import React from "react"
import SEO from "../../components/seo"
import HeroLandingPage from "../../components/pages/shared/hero/landing"
import FeaturesComponent from "../../components/pages/main/landing-features"
import PricingComponent from "../../components/pages/main/detail-pricing"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Layout from "../../components/layout"
import EmailChecklist from "../../components/pages/main/email-checklist"
import Banner from "../../components/pages/main/banner"
import Cards from "../../components/pages/main/cards"
import MainPageInfo from "../../components/pages/main/info"
import Discount from "../../components/pages/main/discount"
import LanguageAccordionComponent from "../../components/pages/language/accordion"

const LanguageSchoolPage = (props) => {
        return (
          <Layout headerColor={"#6D20D2"} page={"landing_language"}>
                  <SEO title="CRM для языковых школ"
                       description="crm для языковых школ + иностранных"
                       url={"https://paraplancrm.ru" + props.location.pathname}
                       ogImage={"https://paraplancrm.ru/images/seo/education.png"}/>
                  <HeroLandingPage
                    title='CRM-система учета'
                    subtitle="для языковых школ"
                    idUnderBlock="#features"
                    offsetHeader={80}
                    list={[
                            '<b>Прозрачное</b> управление бизнес-процессами.',
                            '<b>Неограниченное</b> количество групп.',                            
                            '<b>Удобное</b> расписание и <b>интеграция</b> с мессенджерами.',
                    ]} />
                  <FeaturesComponent title='В системе есть все, что вам нужно' subtitle="А не только инструменты для работы с заявками и контроля отдела продаж" />
                  <Discount />
                  <MainPageInfo
                    title='Работа с CRM - это переход на новый уровень'
                    subtitle='Параплан создан специально для ведения учета клиентов и продаж в языковых школах, лингвистических центрах и школах иностранных языков.' />
                  <EmailChecklist />
                  <Cards />
                  <Banner />
                  <PricingComponent title='Тарифы' mainPage={true} mobileMenu={false} />
                  <ContactFormComponent />
                  <LanguageAccordionComponent />
          </Layout>
        )
}

export default LanguageSchoolPage
