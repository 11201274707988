import React, { useState } from "react"
import styles from "./accordion.module.scss"
import AnimateHeight from "react-animate-height"

const data = [
  {
    name: "about",
    title: "Что такое CRM для языковой школы?",
    content: `CRM для языковой школы — это система, которая учитывает клиентов, их посещения и абонементы. Она помогает управлять учебным процессом и содержит только нужные инструменты: расписание занятий, профили учеников и учителей, автоматический подсчет зарплаты педагогов, отчеты, онлайн-запись клиентов и автоматические уведомления.`,
  },
  {
    name: "use",
    title: "Как внедрить CRM в работу школы иностранных языков?",
    content: `Когда вы определитесь с выбором CRM-системы, важно обучить сотрудников работе с ней. Иногда система хорошо подходит для учёта, но если администраторы и преподаватели не понимают принципы её работы, внедрение может замедлиться.Мы советуем использовать подробный чек-лист для заполнения системы, видео- и текстовые инструкции из базы знаний, а также пройти бесплатное онлайн-обучение работе с системой. Во время презентации менеджер Параплан CRM ответит на все вопросы, связанные с проблемами внедрения CRM в языковой школе.`,
  },
  {
    name: "features",
    title: "Как CRM помогает увеличить продажи языковой школе?",
    content: `Работа с клиентами языковой школы ведется в едином разделе CRM-системы. Информация не хранится на личных устройствах. Каждый лид заносится в воронку продаж, и за него отвечает определенный сотрудник. Руководитель контролирует частоту общения, просматривает переписку и выявляет слабые стороны продаж. В Параплан CRM есть готовые отчеты по воронке и менеджерам, которые помогают оценить эффективность привлечения учеников. Автоматические уведомления напоминают о пробных занятиях. Интеграция с мессенджерами позволяет менеджерам оперативно отвечать на вопросы клиентов.`,
  },
]
const descr = "<p>Нужна ли CRM языковой школе?? Мы в Параплан CRM уверены, что да! Контролировать " +
  "бизнес-процессы в режиме онлайн и автоматизировать рутинные задачи крайне важно. И мы расскажем, как сделать " +
  "быстрый подбор CRM для языковой школы. </p><p>В первую очередь рассмотрите специальные системы для дополнительного " +
  "образования. Благодаря таким функциям, как абонементная система, зарплатные ставки педагогов, статусы посещений " +
  "учеников, Личный кабинет ученика и педагога, этот тип CRM позволяет сделать рабочий процесс эффективным и " +
  "организованным. Вам не требуется большое количество разрозненных документов. В единой системе учета клиентов вы " +
  "сможете объединить все потребности бизнеса.  </p><p>Далее активно тестируйте CRM перед покупкой во время бесплатного " +
  "пробного периода. CRM-система - это не просто программа для учета клиентов. " +
  "Проверьте особенности оформления абонементов и правила списания занятий, вид и удобство расписания, " +
  "настройки автоматических действий, доступы для сотрудников, интеграции, отчеты и аналитику. Это то, что вы точно " +
  "не должны вести вручную. </p><p>И последнее - сравните стоимость подписки у разных СRM-систем для языковых школ " +
  "центра. Выясните, есть ли ограничения по пользователям, количеству клиентов или группам учеников.</p>"

const LanguageAccordionComponent = () => {
  const [tab, setTab] = useState(null)
  return <div className={`container ${styles.tabs}`}>
    <div className={styles.descr}>
      <h2 className={styles.heading}>Советы по внедрению CRM-системы в языковой школе</h2>
      <div className={styles.content} dangerouslySetInnerHTML={{__html: descr }}></div>
    </div>
    <h2 className={styles.heading}>FAQs</h2>
    {data.map(t =>
      <div key={t.name}
           className={`${styles.tab} ${t.name === (tab && tab.name) ? styles.open : ""}`}
           onClick={() => setTab(t !== tab ? t : null)}>
        <div className={styles.info}>
          <div className={styles.title}>{t.title}</div>
          <AnimateHeight duration={300} height={t.name === (tab && tab.name) ? "auto" : 0}>
            <div className={styles.content}>{t.content}</div>
          </AnimateHeight>
        </div>
        <div className={styles.chevron}>
          <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.05287 11.2318L0.435026 2.66346C0.367169 1.27492 1.01181 0.631447 2.33502 0.733047L9.90108 8.28535L17.4671 0.733047C18.7903 0.631447 19.435 1.27492 19.3671 2.66346L10.7832 11.2318C10.1386 11.8414 9.66358 11.8414 9.05287 11.2318Z"
              fill="currentColor"/>
          </svg>
        </div>
      </div>,
    )}
  </div>
}

export default LanguageAccordionComponent
